import { useState } from "react";
import { Title } from "../shared/Title";
import { TitleWithIcon } from "../shared/TitleWithIcon";
import { LottieAnimationHero } from "../shared/lottie/LottieAnimationHero";

export const Hero = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="pt-[104px] h-auto lg:h-[calc(100vh)] w-full px-[20px] sm:px-[40px] xl:px-[120px] 2xl:px-[200px] flex items-center">
            <div className="flex flex-col lg:flex-row justify-between w-full gap-[30px] lg:gap-[0px] ">
                <div className="flex flex-col min-w-auto lg:min-w-[660px] gap-[5vh] lg:gap-[15vh]">
                    <div className="flex items-center lg:items-start flex-col gap-[28px]">
                        <TitleWithIcon
                            title={"AI Focused Startup Accelerator"}
                        />
                        <div className="flex relative flex-col">
                            <Title title={"Empowering founders"} />
                            <Title title={"with visionary business"} />
                            <Title title={"ideas to integrate AI into"} />
                            <Title title={"their strategic roadmap."} />
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap gap-[20px] sm:gap-[40px] justify-center lg:justify-start">
                        <div className="flex flex-row gap-[10px]">
                            <img
                                src="./assets/icons/education-icon.png"
                                alt="education"
                            />
                            <p className="font-helvetica-regular text-[20px]">
                                education
                            </p>
                        </div>
                        <div className="flex flex-row gap-[10px]">
                            <img
                                src="./assets/icons/compass-icon.png"
                                alt="compass"
                            />
                            <p className="font-helvetica-regular text-[20px]">
                                guidance
                            </p>
                        </div>
                        <div className="flex flex-row gap-[10px]">
                            <img
                                src="./assets/icons/support-icon.png"
                                alt="support"
                            />
                            <p className="font-helvetica-regular text-[20px]">
                                support
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="flex items-start lg:items-start justify-center w-auto"
                >
                    <LottieAnimationHero
                        isHovered={isHovered}
                        animationPath="home-hero"
                    />
                </div>
            </div>
        </div>
    );
};
