import { ButtonGreen } from "../shared/ButtonGreen";

export const CallToAction = ({
    title,
    titleTwoGreen,
    description,
    descriptionTwoBold,
    textButton,
}: {
    title: string;
    titleTwoGreen: string;
    description: string;
    descriptionTwoBold: string;
    textButton: string;
}) => {
    return (
        <div className="flex flex-col bg-black-pro gap-[40px] pb-[80px] xl:pb-[120px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <div className="flex justify-center gap-[60px] lg:gap-[80px] flex-col items-center">
                <div className="flex justify-center gap-[40px] flex-col items-center">
                    <p className="text-[36px] md:text-[44px] lg:text-[52px] xl:text-[60px] max-w-[935px] text-white text-center leading-[110%] font-helvetica-medium">
                        {title}
                        {titleTwoGreen.length >= 1 && (
                            <>
                                {" "}
                                <span className="text-green-pro">
                                    {titleTwoGreen}
                                </span>
                            </>
                        )}
                    </p>
                    <p className="text-white text-[20px] max-w-[650px] text-center">
                        {description}
                        {descriptionTwoBold.length >= 1 && (
                            <>
                                {" "}
                                -{" "}
                                <span className="font-helvetica-medium">
                                    {descriptionTwoBold}
                                </span>
                            </>
                        )}
                    </p>
                </div>
                <ButtonGreen title={textButton} openModal={true} />
                <div className="flex flex-row flex-wrap gap-[18px] justify-center">
                    <div className="flex gap-[8px]">
                        <p className="text-green-pro">•</p>
                        <p className="text-gray-pro font-helvetica-medium">
                            We will respond as soon as possible
                        </p>
                    </div>
                    <div className="flex gap-[8px]">
                        <p className="text-green-pro">•</p>
                        <p className="text-gray-pro font-helvetica-medium">
                            We're happy to sign a NDA if requested
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
