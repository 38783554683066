import React from "react";
import { ButtonGreen } from "./ButtonGreen";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center md:justify-between flex-col md:flex-row bg-black-pro opacity-90 gap-[40px] pb-[80px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <div className="max-w-[240px] flex flex-col gap-[28px]">
                <div className="flex gap-[8px] flex-col">
                    <p className="font-helvetica-medium text-white text-[20px] lg:text-[28px]">
                        Let's Talk
                    </p>
                    <p className="text-[16px] text-gray-pro font-helvetica-medium">
                        Contact us to learn more about our program, application
                        process, and how we can help you achieve your business
                        goals with AI.
                    </p>
                </div>
                <ButtonGreen title="Contact Us" openModal={true} />
            </div>
            <div className="w-[240px] md:w-[140px] flex flex-col gap-[20px]">
                <p
                    onClick={() => navigate("/")}
                    className="text-[16px] cursor-pointer text-gray-pro font-helvetica-medium transition-colors duration-300 hover:text-white"
                >
                    Home
                </p>
                <p
                    onClick={() => navigate("/incubator")}
                    className="text-[16px] cursor-pointer text-gray-pro font-helvetica-medium transition-colors duration-300 hover:text-white"
                >
                    Incubator Program
                </p>
                <p
                    onClick={() => navigate("/alumni")}
                    className="text-[16px] cursor-pointer text-gray-pro font-helvetica-medium transition-colors duration-300 hover:text-white"
                >
                    Alumni Program
                </p>
                <p
                    onClick={() => navigate("/about")}
                    className="text-[16px] cursor-pointer text-gray-pro font-helvetica-medium transition-colors duration-300 hover:text-white"
                >
                    About Anserini
                </p>
            </div>
            <div className="w-[240px] md:w-[140px] flex flex-col gap-[20px]">
                <p className="text-[16px] text-gray-pro font-helvetica-medium">
                    GLOBAL KINETICS INC.
                </p>
                <p className="text-[16px] text-gray-pro font-helvetica-medium">
                    contact@anserini.com
                </p>
                <p className="text-[16px] text-gray-pro font-helvetica-medium">
                    6114 La Salle Ave, Oakland, California, 94611-2802
                </p>
            </div>
        </div>
    );
};
