import { useState } from "react";
import { Title } from "../shared/Title";
import { LottieNetworkingOnHover } from "../shared/lottie/LottieNetworkingOnHover";

export const Outcomes = () => {
    return (
        <div className="flex flex-col gap-[40px] lg:gap-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:px-[160px] 2xl:px-[220px]">
            <div className="flex flex-col gap-[12px] items-center lg:items-start">
                <Title title="Supercharged Outcomes" />
                <p className="text-[16px] font-helvetica-regular text-center lg:text-start max-w-[677px]">
                    By the end of the program, you will have developed four key
                    deliverables that will serve as the foundation for your
                    startup's future growth
                </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-[40px] items-center lg:items-start lg:gap-[80px] justify-center">
                <div className="flex flex-col gap-[40px]">
                    <ItemOutcome
                        title="Pitch Deck"
                        description="A compelling pitch deck designed to communicate your vision and the strategic role of AI in your business, crafted to attract and persuade investors."
                    />
                    <ItemOutcome
                        title="AI Use Cases"
                        description="A portfolio of practical AI use cases tailored to your business, demonstrating how AI can solve real-world problems and add value to your customers."
                    />
                </div>
                <div className="flex flex-col gap-[40px]">
                    <ItemOutcome
                        title="Technology Roadmap"
                        description="A detailed technology roadmap outlining the steps to integrate AI into your product or service, ensuring a smooth transition from concept to reality."
                    />
                    <ItemOutcome
                        title="Go-to-Market Plan"
                        description="A comprehensive go-to-market strategy that incorporates AI as a core component, positioning you to capture market share and drive growth."
                    />
                </div>
            </div>
        </div>
    );
};

const ItemOutcome = ({
    title,
    description,
}: {
    title: string;
    description: string;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    return (
        <div
            onMouseEnter={() => {
                handleMouseEnter();
            }}
            onMouseLeave={() => {
                handleMouseLeave();
            }}
            className="flex flex-col items-center lg:items-start gap-[16px] max-w-[412px]"
        >
            <div style={{ width: 86, height: 75 }} className="mx-[24px] ">
                <LottieNetworkingOnHover
                    isHovered={isHovered}
                    animationPath={title.replaceAll(" ", "-").toLowerCase()}
                />
            </div>

            <div>
                <p className={`text-[28px]`}>
                    <mark
                        /* animation */
                        className={`font-helvetica-bold
                transition-colors duration-500
                 ${isHovered ? "bg-green-pro" : "bg-transparent"}`}
                    >
                        <span
                            className={`font-mono text-[34px]  transition-colors duration-500 ${
                                isHovered ? "text-black" : "text-green-pro"
                            }`}
                        >
                            •
                        </span>{" "}
                        {title}
                    </mark>
                </p>
            </div>

            <div className="px-[24px] text-center lg:text-start">
                <p
                    className={`font-helvetica-regular text-[16px] text-black-pro transition-opacity duration-500 ${
                        isHovered ? "opacity-100" : "opacity-60"
                    }`}
                >
                    {description}
                </p>
            </div>
        </div>
    );
};
