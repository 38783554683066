import { Navigate, BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/home";
import { AboutPage } from "../pages/about";
import { Header } from "../components/shared/Header";
import { IncubatorPage } from "../pages/incubator";
import ScrollToTop from "../tools/ScrollToTop";
import { AlumniPage } from "../pages/alumni";
import { ContactModal } from "../components/shared/ContactModal";
import { useContactModalStore } from "../stores/contactModalStore";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";

export const MainRouter = () => {
    const { isShownContactModal } = useContactModalStore();
    useEffect(() => {
        if (isShownContactModal) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isShownContactModal]);

    return (
        <BrowserRouter>
            <AnimatePresence>
                {isShownContactModal && <ContactModal />}
            </AnimatePresence>

            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/incubator" element={<IncubatorPage />} />
                <Route path="/alumni" element={<AlumniPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};
