import { CommandLineIcon, KeyIcon } from "@heroicons/react/16/solid";
import { Title } from "../shared/Title";
import { motion } from "framer-motion";
import { useState } from "react";

export const TailoredExperiences = () => {
    return (
        <div className="flex flex-col items-center lg:items-start gap-[40px] lg:gap-[80px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <div className="flex flex-col max-w-[580px] gap-[16px] items-center lg:items-start">
                <Title title="Tailored Experiences for Founders" />
                <p className="text-[16px] font-helvetica-regular text-black-pro text-center lg:text-start max-w-[677px]">
                    By the end of the program, you will have developed four key
                    deliverables that will serve as the foundation for your
                    startup's future growth
                </p>
            </div>
            <div className="flex flex-col w-full gap-[40px] lg:gap-[60px] lg:flex-row items-center justify-center lg:items-start">
                <Card
                    title="For Business Backgrounds"
                    descriptionOne="If you come from a business-centric background, you'll appreciate our focus on the strategic and competitive advantages of AI. We'll help you understand AI from a business leader's perspective, ensuring you can make informed decisions, drive adoption, and communicate the value of AI to stakeholders."
                    descriptionTwo="You'll learn to navigate the AI landscape without getting bogged down by technical jargon, focusing instead on outcomes, scalability, and return on investment."
                    icon={"key"}
                />
                <Card
                    title="For Technology Backgrounds"
                    descriptionOne="For those with a technology background, our program offers a unique opportunity to translate technical expertise into business success. We'll guide you in identifying market opportunities and articulating the business implications of your technical solutions."
                    descriptionTwo="You'll gain insights into product-market fit, customer acquisition, and how to pitch your technology in a language that resonates with investors and non-technical team members."
                    icon={"command-line"}
                />
            </div>
        </div>
    );
};

const Card = ({
    title,
    descriptionOne,
    descriptionTwo,
    icon,
}: {
    title: string;
    descriptionOne: string;
    descriptionTwo: string;
    icon: "key" | "command-line";
}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <motion.div
            whileHover={{
                scale: 1.1,
                backgroundColor: "#D0FF71",
                transition: { duration: 0.3 },
            }}
            className="max-w-[442px] bg-[#F5F5F5] rounded-[20px] flex flex-col gap-[16px] p-[40px]"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex">
                <div
                    className={`w-[53px] h-[53px] border-[3px] transition-all duration-300 ${
                        isHovered ? "border-[#373737]" : "border-white"
                    } bg-black-pro rounded-full`}
                ></div>
                <div
                    className={`w-[53px] flex items-center justify-center h-[53px] border-[3px] transition-all duration-300 border-white ${
                        isHovered
                            ? "ml-[-7px] bg-white"
                            : "ml-[-18px] bg-green-pro"
                    }  rounded-full`}
                >
                    {icon === "key" && (
                        <KeyIcon
                            className={`transition-all duration-300 ${
                                isHovered
                                    ? "w-[35px] h-[35px]"
                                    : "w-[27px] h-[27px]"
                            }`}
                        />
                    )}
                    {icon === "command-line" && (
                        <CommandLineIcon
                            className={`transition-all duration-300 ${
                                isHovered
                                    ? "w-[35px] h-[35px]"
                                    : "w-[27px] h-[27px]"
                            }`}
                        />
                    )}
                </div>
            </div>
            <div>
                <p className="font-helvetica-medium text-[28px] text-black-pro">
                    {title}
                </p>
            </div>
            <div>
                <p
                    className={`font-helvetica-regular text-[16px] transition-colors duration-300 ${
                        isHovered ? "text-black-pro" : "text-gray-pro"
                    }`}
                >
                    {descriptionOne}
                </p>
            </div>
            <div>
                <p
                    className={`font-helvetica-regular text-[16px] transition-colors duration-300 ${
                        isHovered ? "text-black-pro" : "text-gray-pro"
                    }`}
                >
                    {descriptionTwo}
                </p>
            </div>
        </motion.div>
    );
};
