import { useWindowSize } from "@uidotdev/usehooks";
import "./homecomponents.css";
import { useEffect, useState } from "react";
export const InfiniteTextSlider = () => {
    const sizes = useWindowSize();

    const [howManyMarquees, setHowManyMarquees] = useState(5);

    useEffect(() => {
        if (sizes.width) {
            // one marquee is 772px
            const howManyMarquees = Math.ceil(sizes.width / 600) + 2;
            setHowManyMarquees(howManyMarquees);
        }
    }, [sizes]);

    return (
        <div
            id="animated-text-strip"
            className="bg-black-pro py-[12px] mt-[80px] lg:mt-[0px]"
        >
            {Array.from({ length: howManyMarquees }).map((_, index) => (
                <span key={index} className="marquee">
                    <img
                        src="./assets/icons/lightning-icon.svg"
                        alt="lightning"
                        style={{
                            height: "40px",
                            width: "40px",
                        }}
                    />
                    <p className="font-helvetica-regular font-[28px] text-white">
                        The future of business innovation is inextricably linked
                        to the{" "}
                        <span className="text-green-pro">
                            practical application of artificial intelligence.
                        </span>
                    </p>
                </span>
            ))}
        </div>
    );
};
