import "./sharedcomponents.css";
import { useState } from "react";
import { motion } from "framer-motion";

export const TitleWithIcon = ({ title }: { title: string }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            className="flex cursor-pointer overflow-hidden"
            id="button-container"
            onMouseEnter={(e) => {
                setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                id="icon-container"
                className="flex items-center justify-center w-[44px] h-[44px] rounded-[50%] mr-[-8px] bg-green-pro"
            >
                <span
                    id="icon"
                    className="flex items-center justify-center w-[30px] h-[30px]"
                >
                    <img src="./assets/icons/brain-icon.png" alt="brain" />
                </span>
            </div>
            <button
                id="main-button"
                className="py-[8px] px-[12px] md:px-[24px] justify-center rounded-[54px] cursor-pointer h-[44px] flex items-center relative text-black-pro bg-green-pro"
                style={{
                    width:
                        window.innerWidth >= 768
                            ? title.length * 10 + 30
                            : title.length * 7 + 40,
                }}
            >
                <motion.p
                    className="font-helvetica-regular text-[16px] md:text-[20px]"
                    initial={{ opacity: 1, y: 0 }}
                    animate={{
                        y: isHovered ? -35 : 0,
                    }}
                    transition={{
                        duration: 0.15,
                    }}
                >
                    {title}
                </motion.p>

                <motion.p
                    id="bold"
                    className="font-helvetica-bold text-[16px] md:text-[20px]"
                    initial={{ opacity: 1, y: 35 }}
                    animate={{
                        y: isHovered ? 0 : 35,
                    }}
                    transition={{
                        duration: 0.15,
                    }}
                >
                    {title}
                </motion.p>
            </button>
        </div>
    );
};
