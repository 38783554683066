import React from "react";

export const FooterOdev = () => {
    return (
        <div className="flex flex-row flex-wrap items-center justify-center py-[16px] bg-black-pro opacity-90 gap-[20px] h-auto w-full px-[20px] sm:px-[60px] xl:px-[160px] 2xl:px-[220px]">
            <p className="text-[16px] font-helvetica-medium text-gray-pro">
                Copyright © {new Date().getFullYear()}
            </p>
            <div className="flex flex-row gap-[4px] items-center">
                <p className="text-[16px] font-helvetica-medium text-gray-pro">
                    Powered by{" "}
                </p>
                <img src="./assets/images/logo-odev.svg" alt="odev" />
            </div>
        </div>
    );
};
