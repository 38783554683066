import { motion } from "framer-motion";

export const SuperchargedOutcomes = ({
    styles,
}: {
    styles?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                ...styles,
            }}
            className="relative w-[80px] h-[80px] bg-green-pro rounded-[50%] flex items-center justify-center cursor-pointer"
        >
            <motion.img
                src="./assets/components/text-rotate.svg"
                alt="text"
                width={80}
                height={80}
                whileHover={{ rotate: 180 }}
                transition={{ duration: 0.5 }}
            />
            <img
                src="./assets/icons/bolt-icon.svg"
                width={45}
                height={45}
                style={{
                    position: "absolute",
                    // center the bolt icon
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    pointerEvents: "none",
                }}
                alt="bolt"
            />
        </div>
    );
};
