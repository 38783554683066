import { create } from "zustand";
import { combine, devtools } from "zustand/middleware";

export type ContactModalStoreState = {
    isShownContactModal: boolean;
};

const initialState: ContactModalStoreState = {
    isShownContactModal: false,
};

export const useContactModalStore = create(
    devtools(
        combine(initialState, (set) => ({
            hideContactModal: () => set({ isShownContactModal: false }),
            showContactModal: () => set({ isShownContactModal: true }),
        })),
        { name: "contactModalStore" }
    )
);
