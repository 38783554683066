import { useState } from "react";
import { motion } from "framer-motion";
import "./headercomponents.css";
import { useLocation, useNavigate } from "react-router-dom";

export const HeaderItem = ({
    title,
    route,
    showItemsMobile,
}: {
    title: string;
    route: string;
    showItemsMobile: boolean;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div
            className={`header-item h-[22px] ${
                location.pathname === route && "bg-green-pro"
            } `}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate(route)}
            style={{
                width: title.length * 7 + 30,
                height: 22,
                overflow: "hidden",
                display: "flex",
                alignItems: showItemsMobile ? "start" : "center",
                justifyContent: showItemsMobile ? "start" : "center",
            }}
        >
            <motion.p
                id="fonts-abs"
                className={`font-helvetica-regular ${
                    showItemsMobile
                        ? "justify-start items-start"
                        : "justify-center items-center"
                }`}
                initial={{ y: 0 }}
                animate={{
                    y: isHovered ? -22 : 0,
                }}
                transition={{
                    duration: 0.2,
                }}
                style={{
                    display: "flex",
                    alignItems: showItemsMobile ? "start" : "center",
                    justifyContent: showItemsMobile ? "start" : "center",
                }}
            >
                {title}
            </motion.p>

            <motion.p
                id="fonts-abs"
                className={`font-helvetica-bold ${
                    showItemsMobile
                        ? "justify-start items-start"
                        : "justify-center items-center"
                }`}
                initial={{ y: 22 }}
                animate={{
                    y: isHovered ? 0 : 22,
                }}
                style={{
                    display: "flex",
                    alignItems: showItemsMobile ? "start" : "center",
                    justifyContent: showItemsMobile ? "start" : "center",
                }}
                transition={{
                    duration: 0.2,
                }}
            >
                {title}
            </motion.p>
        </div>
    );
};
