import React, { useState } from "react";
import { Title } from "../shared/Title";

export const Benefits = () => {
    return (
        <div className="flex flex-col pb-[80px] xl:pb-[120px] items-center lg:items-start gap-[40px] lg:gap-[80px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <Title title="Benefits for all" />
            <div className="flex flex-row gap-[80px]">
                <div className="w-1/2 rounded-[20px] hidden md:flex">
                    <img
                        className="h-full rounded-[20px]"
                        src="./assets/images/benefits.png"
                        style={{
                            objectFit: "cover",
                        }}
                        alt="benefits"
                    />
                </div>

                <div className="flex flex-col gap-[40px]">
                    <ItemBenefit
                        title="Business Insights"
                        description="A clear understanding of how AI can drive business growth and innovation."
                    />
                    <ItemBenefit
                        title="Perficient Communication"
                        description="The ability to communicate the benefits of AI to a diverse audience, including investors, customers, and team members."
                    />
                    <ItemBenefit
                        title="Implementation Blueprint"
                        description="A strategic plan for implementing AI that aligns with your business goals and market needs."
                    />
                    <ItemBenefit
                        title="Leadership Network"
                        description="A network of peers, mentors, and industry contacts that will continue to support your growth beyond the program."
                    />
                </div>
            </div>
        </div>
    );
};

const ItemBenefit = ({
    title,
    description,
}: {
    title: string;
    description: string;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    return (
        <div
            onMouseEnter={() => {
                handleMouseEnter();
            }}
            onMouseLeave={() => {
                handleMouseLeave();
            }}
            className="flex flex-col items-start max-w-[412px]"
        >
            <div>
                <p className={`text-[28px] text-black-pro`}>
                    <mark
                        /* animation */
                        className={`font-helvetica-bold
                transition-colors duration-500 text-black-pro
                 ${isHovered ? "bg-green-pro" : "bg-transparent"}`}
                    >
                        <span
                            className={`font-mono text-[34px]  transition-colors duration-500 ${
                                isHovered ? "text-black-pro" : "text-green-pro"
                            }`}
                        >
                            •
                        </span>{" "}
                        {title}
                    </mark>
                </p>
            </div>

            <div className="px-[24px] text-start">
                <p
                    className={`font-helvetica-regular text-[16px] text-black-pro transition-opacity duration-500 ${
                        isHovered ? "opacity-100" : "opacity-60"
                    }`}
                >
                    {description}
                </p>
            </div>
        </div>
    );
};
