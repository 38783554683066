import { InfiniteTextSlider } from "../components/home/InfiniteTextSlider";
import { Hero } from "../components/home/Hero";
import { Mision } from "../components/home/Mision";
import { WhatWeOffer } from "../components/home/WhatWeOffer";
import { Records } from "../components/home/Records";
import { Footer } from "../components/shared/Footer";
import { CallToAction } from "../components/shared/CallToAction";
import { FooterOdev } from "../components/shared/FooterOdev";

export const HomePage = () => {
    return (
        <div className="flex flex-col">
            <Hero />
            <InfiniteTextSlider />
            <Mision />
            <WhatWeOffer />
            <Records />
            <CallToAction
                title="Are you ready to take your startup to the next level with"
                titleTwoGreen="Pragmatic AI?"
                description="Let's embark on this transformative journey together. Welcome to the forefront of business innovation"
                descriptionTwoBold="welcome to Pragmatic AI."
                textButton="Let's Talk"
            />
            <Footer />
            <FooterOdev />
        </div>
    );
};
