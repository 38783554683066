import { useWindowSize } from "@uidotdev/usehooks";
import { SuperchargedOutcomes } from "../shared/SuperchargedOutcomes";
import { BoltIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { useState } from "react";

const avatars = [
    {
        number: 1,
    },
    {
        number: 2,
    },
    {
        number: 3,
    },
];

export const Transformation = () => {
    const size = useWindowSize();
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className="flex flex-col  mt-[80px] lg:mt-[0px] lg:flex-row justify-center items-center lg:items-start gap-[40px] lg:justify-between pb-[80px] xl:pb-[120px] h-auto w-full px-[20px] sm:px-[60px] xl:px-[160px] 2xl:px-[220px] overflow-hidden">
            <div className="flex flex-col gap-[16px]">
                <div className="flex gap-[8px]">
                    <p className="text-[28px] text-green-pro">•</p>
                    <p className="text-[28px] text-black-pro font-helvetica-medium">
                        3 months of transformation
                    </p>
                </div>
                <div>
                    <div className="flex justify-center lg:justify-start">
                        {avatars.map((avatar, index) => (
                            <Avatar
                                key={index}
                                index={index}
                                number={avatar.number}
                            />
                        ))}
                        {size && size?.width && size?.width > 370 && (
                            <SuperchargedOutcomes
                                styles={{
                                    zIndex: 13,
                                    marginLeft: -10,
                                    border: "3px solid #fff",
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div
                className="max-w-[380px] flex flex-col gap-[20px] overflow-hidden"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <p className="text-[16px] font-helvetica-regular">
                    Our accelerator runs for a full quarter, providing an
                    intensive, focused period of{" "}
                    <mark className="bg-green-pro">
                        growth and development.
                    </mark>{" "}
                    This timeframe is optimized to help you achieve significant
                    progress without losing{" "}
                    <span className="underline">momentum.</span>{" "}
                    <QuestionMarkCircleIcon
                        fontSize={16}
                        style={{
                            height: 16,
                            width: 16,
                            marginBottom: 10,
                            display: "inline-block",
                        }}
                    />
                </p>
                <Card isHovered={isHovered} />
            </div>
        </div>
    );
};

const Avatar = ({ number, index }: { number: number; index: number }) => {
    return (
        <div
            style={{
                zIndex: 10 + index + 1,
                marginLeft: index > 0 ? -10 : 0,
                width: 80,
                height: 80,
                backgroundColor: "#373737",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "3px solid #fff",
            }}
        >
            <p className="font-helvetica-bold text-[20px] text-green-pro">
                {number}
            </p>
        </div>
    );
};

const Card = ({ isHovered }: { isHovered: boolean }) => {
    return (
        <div
            className={`max-w-[352px] bg-green-pro px-4 pt-3 pb-4 flex flex-col gap-2 rounded-[20px] transition-all duration-500 ease-in-out transform ${
                isHovered
                    ? "translate-x-0 opacity-100"
                    : "translate-x-full opacity-0"
            }`}
        >
            <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col">
                    <p className="text-[20px] font-helvetica-bold">momentum:</p>
                    <p className="text-[12px] font-helvetica-regular">
                        noun - /mə(ʊ)ˈmɛntəm/
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <BoltIcon
                        fontSize={35}
                        style={{
                            height: 35,
                            width: 35,
                        }}
                    />
                </div>
            </div>
            <div>
                <p className="font-helvetica-regular text-[16px]">
                    the force or energy gained by a moving idea.
                </p>
            </div>
        </div>
    );
};
