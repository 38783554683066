import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";

export const LottieAnimationHero = ({
    isHovered,
    animationPath,
}: {
    isHovered: boolean;
    animationPath: string;
}) => {
    const animationRef = useRef(null);

    const [animationData, setAnimationData] = useState(null);

    useEffect(() => {
        import(`../lottie-assets/${animationPath}.json`).then((animation) => {
            setAnimationData(animation.default);
        });
    }, [animationPath]);

    useEffect(() => {
        if (isHovered && animationData !== null) {
            //@ts-ignore
            animationRef.current?.play();
        } else {
            if (animationData === null) return;
            //@ts-ignore
            animationRef.current?.goToAndStop(0, true);
        }
    }, [isHovered, animationData]);

    if (animationData === null) return null;

    return (
        <Lottie
            className="w-[70%] md:w-[40%] lg:w-[90%] xl:w-[90%] 2xl:w-[90%]"
            animationData={animationData}
            lottieRef={animationRef}
            autoplay={false}
        />
    );
};
