import { useState } from "react";
import { Title } from "../shared/Title";
import { LottieAnimationNormal } from "../shared/lottie/LottieAnimationNormal";

const items = [
    {
        title: "Strategic AI Integration",
        image: "./assets/videos/video-what-we-offer-1.mp4",
        description:
            "We guide you on how to incorporate AI into your business model, ensuring that it aligns with your core objectives and market demands.",
    },
    {
        title: "Expert Mentorship",
        image: "./assets/videos/video-what-we-offer-1.mp4",
        description:
            "Gain insights from industry leaders who have successfully leveraged AI to scale their businesses and achieve market leadership.",
    },
    {
        title: "Customized Roadmaps",
        image: "./assets/videos/video-what-we-offer-1.mp4",
        description:
            "Every business is unique, and so is our approach. We tailor AI implementation strategies that resonate with your specific business needs.",
    },
    {
        title: "Networking Opportunities",
        image: "./assets/videos/video-what-we-offer-1.mp4",
        description:
            "Connect with a community of like-minded entrepreneurs, potential investors, and partners who are equally passionate about the business potential of AI.",
    },
    {
        title: "Educational Workshops",
        image: "./assets/videos/video-what-we-offer-1.mp4",
        description:
            "Participate in workshops and seminars that focus on the practical aspects of AI in business, from data strategy to AI ethics.",
    },
    {
        title: "Funding Assistance",
        image: "./assets/videos/video-what-we-offer-1.mp4",
        description:
            "We help you articulate the value proposition of AI in your business to attract the right investors and secure the funding you need to grow.",
    },
];
export const WhatWeOffer = () => {
    return (
        <div className="flex flex-col gap-[40px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <Title title="What we offer" />
            {/* Container offer items */}
            <div className="flex flex-col w-full">
                {/* Offer item */}
                {items.map((item, index) => (
                    <div key={index}>
                        <OfferItem
                            title={item.title}
                            description={item.description}
                            video={item.image}
                        />
                        {index < items.length - 1 && (
                            <hr className="mt-[12px] mb-[12px]" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const OfferItem = ({
    title,
    description,
    video,
}: {
    title: string;
    description: string;
    video: string;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="flex flex-col lg:flex-row justify-between py-[20px] h-auto desktop:h-[152px] "
        >
            <div>
                <p className={`text-[28px]`}>
                    <mark
                        /* animation */
                        className={`font-helvetica-bold
                        transition-colors duration-500
                         ${isHovered ? "bg-green-pro" : "bg-transparent"}`}
                    >
                        <span
                            className={`font-mono text-[34px]  transition-colors duration-500 ${
                                isHovered ? "text-black" : "text-green-pro"
                            }`}
                        >
                            •
                        </span>{" "}
                        {title}
                    </mark>
                </p>
            </div>
            {isHovered && (
                <div
                    className={`hidden desktop:flex ${
                        isHovered ? "transition-all duration-500" : ""
                    }`}
                    style={{ width: 127, height: 112 }}
                >
                    <LottieAnimationNormal
                        animationPath={title
                            .replaceAll(" ", "-")
                            .toLocaleLowerCase()}
                    />
                </div>
            )}

            <div className="max-w-[540px]">
                <p
                    className={`font-helvetica-regular text-[20px] text-black-pro transition-opacity duration-500 ${
                        isHovered ? "opacity-100" : "opacity-60"
                    }`}
                >
                    {description}
                </p>
            </div>
        </div>
    );
};
