import React from "react";
import { HeroAlumni } from "../components/alumni/HeroAlumni";
import { CardsAlumni } from "../components/alumni/CardsAlumni";
import { Resources } from "../components/alumni/Resources";
import { BigCardAlumni } from "../components/alumni/BigCardAlumni";
import { CallToAction } from "../components/shared/CallToAction";
import { Footer } from "../components/shared/Footer";
import { FooterOdev } from "../components/shared/FooterOdev";

export const AlumniPage = () => {
    return (
        <div className="flex flex-col">
            <HeroAlumni />
            <CardsAlumni />
            <Resources />
            <BigCardAlumni />
            <CallToAction
                title="Join Our Legacy of Success"
                titleTwoGreen=""
                description="Graduates of the Pragmatic AI Accelerator Program are part of an elite group of startups that are shaping the future of business with AI. Our comprehensive post-program support ensures that you have the resources, connections, and knowledge needed to thrive in the competitive landscape."
                descriptionTwoBold=""
                textButton="Apply Now"
            />
            <Footer />
            <FooterOdev />
        </div>
    );
};
