import { Logo } from "../header/Logo";
import { HeaderItem } from "../header/HeaderItem";
import { ButtonBlack } from "./ButtonBlack";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Bars3Icon } from "@heroicons/react/16/solid";

const HeaderItems = [
    { title: "Incubator Program", route: "/incubator" },
    { title: "Alumni Program", route: "/alumni" },
    { title: "About Anserini", route: "/about" },
];
export const Header = () => {
    const navigate = useNavigate();

    const [hasScrolledDown, setHasScrolledDown] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [showItemsMobile, setShowItemsMobile] = useState(false);

    // Detect if the user has scrolled
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setHasScrolledDown(true);
            } else {
                setHasScrolledDown(false);
                setShowItemsMobile(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // If the user is scrolling down for more than 100px, hide the header. If the user scrolls up, show the header
    useEffect(() => {
        let lastScrollTop = 0;
        const handleScroll = () => {
            const currentScroll = Math.max(window.pageYOffset, 0); // Ignore negative scroll values

            if (currentScroll > lastScrollTop && currentScroll > 300) {
                // Check if user has scrolled more than 100px
                setShowHeader(false);
                setShowItemsMobile(false);
            } else {
                setShowHeader(true);
            }

            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (window.scrollY === 0 && !showItemsMobile) {
            setHasScrolledDown(false);
        }
        if (window.scrollY === 0 && showItemsMobile) {
            setHasScrolledDown(true);
        }
    }, [showItemsMobile]);

    return (
        <div
            className={`px-[40px] fixed w-full items-start lg:items-start justify-center ${
                hasScrolledDown ? "bg-transparent" : "bg-white "
            } z-[9998] xl:px-[120px] 2xl:px-[200px] h-[56px] flex mt-2 transition-transform duration-300 ${
                showHeader ? "transform-y-0" : "transform -translate-y-[80px]"
            }`}
        >
            <div
                className={`flex flex-row items-center justify-between transition-all duration-200 ${
                    hasScrolledDown
                        ? ` ${
                              showItemsMobile
                                  ? "w-[100%] flex-col"
                                  : "w-[280px] lg:w-[100%]"
                          } bg-opacity-90 bg-[#f5f5f5] backdrop-blur-sm px-[16px] rounded-[20px]`
                        : "w-full"
                }`}
            >
                <div className="flex flex-row items-center justify-between w-full">
                    <div
                        className="cursor-pointer"
                        onClick={() => navigate("/")}
                    >
                        <Logo />
                    </div>

                    <div className="flex-row hidden lg:flex items-center gap-[20px]">
                        {HeaderItems.map((item) => (
                            <HeaderItem
                                key={item.title}
                                title={item.title}
                                route={item.route}
                                showItemsMobile={showItemsMobile}
                            />
                        ))}
                    </div>

                    <div className="hidden lg:flex">
                        <ButtonBlack openModal={true} />
                    </div>
                    <div className="flex lg:hidden">
                        <Bars3Icon
                            className="w-[25px] h-[25px] cursor-pointer"
                            onClick={() => {
                                setShowItemsMobile((current) => !current);
                            }}
                        />
                    </div>
                </div>

                <div
                    className={`${
                        showItemsMobile ? "flex w-full py-5" : "hidden"
                    } transition-all duration-1000 lg:hidden`}
                >
                    <div className="flex-col w-full flex items-start justify-start gap-[20px]">
                        {HeaderItems.map((item) => (
                            <HeaderItem
                                key={item.title}
                                title={item.title}
                                route={item.route}
                                showItemsMobile={showItemsMobile}
                            />
                        ))}
                        <ButtonBlack openModal={true} />
                    </div>
                </div>
            </div>
        </div>
    );
};
