import { useWindowSize } from "@uidotdev/usehooks";
import React, { useState } from "react";

const CardsAlumniInformation = [
    {
        title: "Personalized Guidance",
        description:
            "Graduates of the Pragmatic AI Accelerator Program continue to have access to our network of mentors. These industry leaders, successful entrepreneurs, and AI experts are available to provide personalized guidance, strategic advice, and support as you navigate the challenges and opportunities of growing your AI-driven business.",
        titleIcon: "Ongoing Mentoring",
        icon: "./assets/icons/compass-icon.svg",
    },
    {
        title: "Access to Capital",
        description:
            "A critical component of scaling your startup is securing the necessary funding. Our graduates gain exclusive access to our network of angel investors, venture capitalists, and corporate partners who are actively seeking to invest in promising AI ventures. We facilitate introductions and pitch opportunities, increasing your chances of securing investment.",
        titleIcon: "Investment Connections",
        icon: "./assets/icons/investment-icon.svg",
    },
    {
        title: "Showcasing Success",
        description:
            "We take pride in the achievements of our graduates and actively promote your success stories through our channels. This includes features on our website, social media shoutouts, and opportunities to be highlighted in our newsletters and press releases, significantly boosting your startup's visibility.",
        titleIcon: "Visibility and Branding",
        icon: "./assets/icons/visibility-icon.svg",
    },
    {
        title: "Lifelong Learning",
        description:
            "The field of AI is ever-evolving, and so is our curriculum. Our graduates have lifetime access to updated course materials, ensuring you remain at the forefront of AI innovation. Additionally, alumni are invited to exclusive workshops and seminars designed to deepen their knowledge and skills.",
        titleIcon: "Continuous Growth",
        icon: "./assets/icons/education-icon.svg",
    },
];
export const CardsAlumni = () => {
    return (
        <div className="flex flex-col mt-[80px] lg:mt-[0px] justify-center items-center  gap-[40px] lg:justify-between h-auto w-full px-[20px] sm:px-[60px] xl:px-[160px] 2xl:px-[220px]">
            <div className="flex flex-col lg:flex-row gap-[40px] lg:gap-[80px]">
                <Card
                    title={CardsAlumniInformation[0].title}
                    description={CardsAlumniInformation[0].description}
                    titleIcon={CardsAlumniInformation[0].titleIcon}
                    index={0}
                    icon={CardsAlumniInformation[0].icon}
                />
                <Card
                    title={CardsAlumniInformation[1].title}
                    description={CardsAlumniInformation[1].description}
                    titleIcon={CardsAlumniInformation[1].titleIcon}
                    index={1}
                    icon={CardsAlumniInformation[1].icon}
                />
            </div>
            <div className="flex flex-col lg:flex-row gap-[40px] lg:gap-[80px]">
                <Card
                    title={CardsAlumniInformation[2].title}
                    description={CardsAlumniInformation[2].description}
                    titleIcon={CardsAlumniInformation[2].titleIcon}
                    index={2}
                    icon={CardsAlumniInformation[2].icon}
                />
                <Card
                    title={CardsAlumniInformation[3].title}
                    description={CardsAlumniInformation[3].description}
                    titleIcon={CardsAlumniInformation[3].titleIcon}
                    index={3}
                    icon={CardsAlumniInformation[3].icon}
                />
            </div>
        </div>
    );
};

const Card = ({
    title,
    description,
    titleIcon,
    index,
    icon,
}: {
    title: string;
    description: string;
    titleIcon: string;
    index: number;
    icon: string;
}) => {
    const [isCentered, setIsCentered] = useState(false);
    const sizes = useWindowSize();

    return (
        <div
            onMouseEnter={() => setIsCentered(true)}
            onMouseLeave={() => setIsCentered(false)}
            className="p-[40px] max-w-[518px] flex flex-col gap-[20px] rounded-[20px] group transition-colors duration-300 hover:bg-[#F5F5F5]"
        >
            <div className="relative rounded-[20px]">
                {/* Square */}
                {sizes && sizes.width && sizes?.width > 380 && (
                    <div
                        className={` absolute transition-all duration-300 ${
                            isCentered
                                ? "left-1/2 top-1/2 translate-x-[-50%] scale-125 translate-y-[-50%]"
                                : "left-[-10px] top-[-10px] scale-100"
                        }`}
                    >
                        <TitleWithIcon title={titleIcon} icon={icon} />
                    </div>
                )}

                <img
                    src={`./assets/images/alumni-card-${index}.png`}
                    alt="Card Alumni"
                    className={`rounded-[20px] `}
                />
            </div>
            <div className="flex flex-col gap-[10px]">
                <p className="text-[28px] font-helvetica-medium text-black-pro">
                    {title}
                </p>
                <p className="text-[16px] font-helvetica-regular text-gray-pro transition-colors duration-300 group-hover:text-black-pro">
                    {description}
                </p>
            </div>
        </div>
    );
};

const TitleWithIcon = ({ title, icon }: { title: string; icon: string }) => {
    return (
        <div className="flex">
            <div
                id="icon-container"
                className="flex items-center justify-center w-[28px] h-[28px] rounded-[50%] z-20 mr-[-8px] bg-green-pro"
            >
                <span
                    id="icon"
                    className="flex items-center justify-center w-[20px] h-[20px]"
                >
                    <img src={icon} alt="icon-card" />
                </span>
            </div>
            <button
                id="main-button"
                className="py-[8px] px-[10px] justify-center rounded-[54px] z-10 cursor-pointer h-[28px] flex items-center relative text-black-pro bg-green-pro"
                style={{
                    width: title.length * 7 + 38,
                }}
            >
                <p className="font-helvetica-regular text-[16px]">{title}</p>
            </button>
        </div>
    );
};
