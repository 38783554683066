import React, { useState } from "react";
import { Title } from "../shared/Title";
import { LottieNetworkingOnHover } from "../shared/lottie/LottieNetworkingOnHover";

const ItemsOutcomesInformation = [
    {
        title: "Toolkits and Software",
        description:
            "Discounts and special offers on leading AI development tools and software to keep your technology stack cutting-edge.",
    },
    {
        title: "Talent Network",
        description:
            "Access to our talent network of developers, data scientists, and AI specialists available for hire to help scale your technical team.",
    },
    {
        title: "Continued Learning",
        description:
            "Opportunities for you and your team to participate in workshops, webinars, and courses to stay abreast of the latest AI technologies and methodologies.",
    },
];

export const Resources = () => {
    return (
        <div className="flex flex-col items-center lg:items-start gap-[40px] lg:gap-[60px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <div className="flex flex-col items-center lg:items-start gap-[16px] max-w-[739px]">
                <Title title="Development and Data Science Resources" />
                <p className="text-[16px] text-center lg:text-start max-w-[665px] font-helvetica-regular text-black-pro">
                    Continuing the journey of AI integration requires ongoing
                    technical development. Our graduates have privileged access
                    to a suite of development and data science resources,
                    including:
                </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-[40px] self-center justify-center">
                {
                    /* Items */
                    ItemsOutcomesInformation.map((item, index) => (
                        <ItemOutcome
                            key={index}
                            title={item.title}
                            description={item.description}
                            animationPath={item.title
                                .replaceAll(" ", "-")
                                .toLowerCase()}
                        />
                    ))
                }
            </div>
        </div>
    );
};

const ItemOutcome = ({
    title,
    description,
    animationPath,
}: {
    title: string;
    description: string;
    animationPath: string;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    return (
        <div
            onMouseEnter={() => {
                handleMouseEnter();
            }}
            onMouseLeave={() => {
                handleMouseLeave();
            }}
            className="flex flex-col items-center lg:items-start gap-[16px] max-w-[333px]"
        >
            <div style={{ width: 86, height: 75 }} className="mx-[24px] ">
                <LottieNetworkingOnHover
                    isHovered={isHovered}
                    animationPath={animationPath}
                />
            </div>

            <div>
                <p className={`text-[28px]`}>
                    <mark
                        /* animation */
                        className={`font-helvetica-bold
                transition-colors duration-500
                 ${isHovered ? "bg-green-pro" : "bg-transparent"}`}
                    >
                        <span
                            className={`font-mono text-[34px]  transition-colors duration-500 ${
                                isHovered ? "text-black" : "text-green-pro"
                            }`}
                        >
                            •
                        </span>{" "}
                        {title}
                    </mark>
                </p>
            </div>

            <div className="px-[24px] text-center lg:text-start">
                <p
                    className={`font-helvetica-regular text-[16px] text-black-pro transition-opacity duration-500 ${
                        isHovered ? "opacity-100" : "opacity-60"
                    }`}
                >
                    {description}
                </p>
            </div>
        </div>
    );
};
