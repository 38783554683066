import Lottie from "lottie-react";
import { useEffect, useState } from "react";

export const LottieAnimationNormal = ({
    animationPath,
}: {
    animationPath: string;
}) => {
    const [animationData, setAnimationData] = useState(null);

    useEffect(() => {
        import(`../lottie-assets/${animationPath}.json`).then((animation) => {
            setAnimationData(animation.default);
        });
    }, [animationPath]);

    if (animationData === null) return null;

    return <Lottie animationData={animationData} />;
};
