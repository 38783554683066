import { Title } from "../shared/Title";

import { useState } from "react";
import { LottieAnimationHero } from "../shared/lottie/LottieAnimationHero";

export const HeroAlumni = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="pt-[104px] h-auto lg:h-[calc(100vh)] w-full px-[20px] sm:px-[40px] xl:px-[120px] 2xl:px-[200px] flex items-center">
            <div className="flex flex-col lg:flex-row justify-between w-full gap-[30px] lg:gap-[0px] ">
                <div className="flex flex-col min-w-auto lg:min-w-[660px] gap-[5vh] lg:gap-[15vh]">
                    <div className="flex items-center lg:items-start flex-col gap-[28px]">
                        <div className="flex relative flex-col gap-[28px] max-w-[640px]">
                            <div>
                                <p className="text-[20px] text-center lg:text-start font-helvetica-medium">
                                    Beyond the Program:
                                </p>
                                <Title
                                    title={"Continuing Support for Graduates"}
                                />
                            </div>
                            <p className="text-[18px] lg:text-[20px] font-helvetica-regular text-center lg:text-start">
                                At Pragmatic AI Accelerator, our commitment to
                                your startup's success extends far beyond the
                                conclusion of our three-month program. We
                                understand that the journey of integrating AI
                                into your business is ongoing, and our support
                                for our graduates is unwavering. Here's how we
                                continue to assist our alumni in their
                                post-program growth and development.
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap gap-[20px] sm:gap-[40px] justify-center lg:justify-start"></div>
                </div>
                <div
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="flex items-start lg:items-start justify-center w-auto"
                >
                    <LottieAnimationHero
                        isHovered={isHovered}
                        animationPath="alumni-hero"
                    />
                </div>
            </div>
        </div>
    );
};
