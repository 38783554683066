import { useState } from "react";
import { Title } from "../shared/Title";
import { LottieAnimationHero } from "../shared/lottie/LottieAnimationHero";

export const HeroIncubator = () => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className="pt-[104px] h-auto lg:h-[calc(100vh)] w-full px-[20px] sm:px-[40px] xl:px-[120px] 2xl:px-[200px] flex items-center">
            <div className="flex flex-col lg:flex-row justify-between w-full gap-[30px] lg:gap-[0px] ">
                <div className="flex flex-col min-w-auto lg:min-w-[660px] gap-[5vh] lg:gap-[15vh]">
                    <div className="flex items-center lg:items-start flex-col gap-[28px]">
                        <div className="flex relative flex-col gap-[28px] max-w-[640px]">
                            <Title title={"Incubator Program"} />
                            <p className="text-[18px] lg:text-[20px] font-helvetica-regular text-center lg:text-start">
                                Welcome to the Pragmatic AI Accelerator Program,
                                a meticulously crafted three-month journey
                                designed to position your startup at the
                                intersection of business acumen and AI
                                innovation. Our program is tailored to equip you
                                with the{" "}
                                <span className="font-helvetica-bold">
                                    tools, knowledge, and strategic insight
                                </span>{" "}
                                necessary to elevate your business through the
                                power of AI.
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap gap-[20px] sm:gap-[40px] justify-center lg:justify-start"></div>
                </div>
                <div
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="flex items-start lg:items-start justify-center w-auto"
                >
                    <LottieAnimationHero
                        isHovered={isHovered}
                        animationPath="incubator-hero"
                    />
                </div>
            </div>
        </div>
    );
};
