import { LightBulbIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import { useContactModalStore } from "../../stores/contactModalStore";

export const BigCardAlumni = () => {
    const [isHovered, setIsHovered] = useState(false);

    const { showContactModal, hideContactModal, isShownContactModal } =
        useContactModalStore();

    return (
        <div className="flex items-center justify-center lg:items-start gap-[40px] lg:gap-[60px] pt-[80px] pb-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:pb-[120px] xl:px-[160px] 2xl:px-[220px]">
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="group flex flex-col lg:flex-row"
            >
                <div className="w-full lg:w-1/2 rounded-t-[20px] lg:rounded-s-[20px] max-w-[600px] overflow-hidden relative">
                    <img
                        className="h-full z-20 rounded-t-[20px] lg:rounded-s-[20px] transition-all duration-500 group-hover:scale-110"
                        src="./assets/images/big-card-image.png"
                        style={{
                            objectFit: "cover",
                        }}
                        alt="benefits"
                    />
                    <div className="absolute bottom-10 left-1/2 translate-x-[-50%] group-hover:opacity-0 transition-opacity duration-500">
                        <TitleWithIcon title="Networking Opportunities" />
                    </div>
                    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] group-hover:opacity-100 opacity-0 transition-opacity duration-500">
                        <img
                            onClick={() => {
                                if (!isShownContactModal) {
                                    showContactModal();
                                    return;
                                }
                                if (isShownContactModal) {
                                    hideContactModal();
                                    return;
                                }
                            }}
                            className="h-[100px] w-[100px] z-20 cursor-pointer"
                            src="./assets/images/apply-now-badge.svg"
                            style={{
                                width: 100,
                                height: 100,
                            }}
                            alt="badge"
                        />
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-[20px] lg:gap-[40px] justify-between rounded-b-[20px] lg:rounded-e-[20px] max-w-[600px] p-[40px] bg-[#F5F5F5]">
                    <div className="flex flex-col gap-[20px] lg:gap-[40px]">
                        <div className="flex">
                            <div
                                className={`w-[53px] h-[53px] border-[3px] transition-all duration-500 ${
                                    isHovered
                                        ? "border-[#373737]"
                                        : "border-[#373737]"
                                } bg-black-pro rounded-full`}
                            ></div>
                            <div
                                className={`w-[53px] flex items-center justify-center h-[53px] border-[3px] transition-all duration-500 border-white ${
                                    isHovered
                                        ? "ml-[-7px] bg-green-pro"
                                        : "ml-[-18px] bg-green-pro"
                                }  rounded-full`}
                            >
                                <LightBulbIcon
                                    className={`transition-all duration-500 ${
                                        isHovered
                                            ? "w-[35px] h-[35px]"
                                            : "w-[27px] h-[27px]"
                                    }`}
                                />
                            </div>
                        </div>
                        <div>
                            <p className="text-black-pro leading-[36px] md:leading-[44px] lg:leading-[52px] xl:leading-[60px] font-helvetica-medium text-[36px] md:text-[44px] lg:text-[52px] xl:text-[60px]">
                                A Community of Innovators
                            </p>
                        </div>
                    </div>
                    <div>
                        <p className="text-[16px] text-black-pro font-helvetica-regular">
                            Being part of the Pragmatic AI Accelerator alumni
                            network means joining a vibrant community of
                            like-minded entrepreneurs. Our regular networking
                            events, alumni meetups, and industry conferences
                            provide ample opportunities to connect, collaborate,
                            and share insights with peers and industry leaders.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TitleWithIcon = ({ title }: { title: string }) => {
    return (
        <div className="flex">
            <div
                id="icon-container"
                className="flex items-center justify-center w-[28px] h-[28px] rounded-[50%] z-20 mr-[-8px] bg-green-pro"
            >
                <span
                    id="icon"
                    className="flex items-center justify-center w-[20px] h-[20px]"
                >
                    <img
                        src="./assets/icons/networking-icon.svg"
                        alt="networking"
                    />
                </span>
            </div>
            <button
                id="main-button"
                className="py-[8px] px-[10px] justify-center rounded-[54px] z-10 cursor-pointer h-[28px] flex items-center relative text-black-pro bg-green-pro"
                style={{
                    width: title.length * 7 + 38,
                }}
            >
                <p className="font-helvetica-regular text-[16px]">{title}</p>
            </button>
        </div>
    );
};
