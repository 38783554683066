import { useRef, useState } from "react";
import "./homecomponents.css";
import { motion } from "framer-motion";
import { PlayIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { useWindowSize } from "@uidotdev/usehooks";
export const Records = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [showDot, setShowDot] = useState(false);

    return (
        <div className="flex flex-col  gap-[40px] pb-[80px] xl:pb-[120px] pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px]">
            <div className="flex flex-col gap-[80px]">
                <div
                    onMouseEnter={() => {
                        setShowDot(true);
                        setIsHovered(true);
                    }}
                    onMouseLeave={() => {
                        setShowDot(false);
                        setIsHovered(false);
                    }}
                >
                    <TextWithAnimations
                        isHovered={isHovered}
                        showDot={showDot}
                    />
                </div>

                <div className="flex flex-col items-center lg:items-start lg:flex-row gap-[60px] lg:gap-[160px] ">
                    {/* video */}
                    <div className="flex-none">
                        <VideoPlayer />
                    </div>

                    <p className="font-helvetica-regular text-center lg:text-start text-[20px] lg:text-[28px]">
                        We are a{" "}
                        <mark className="bg-green-pro">collective</mark> of
                        entrepreneurs, AI experts, and business strategists who
                        are dedicated to the practical application of AI in the
                        business world.
                    </p>
                </div>
            </div>
        </div>
    );
};

const TextWithAnimations = ({
    isHovered,
    showDot,
}: {
    isHovered: boolean;
    showDot: boolean;
}) => {
    return (
        <p className="text-[36px] md:text-[44px] max-w-[990px] lg:text-[52px] xl:text-[60px] text-black-pro text-center lg:text-start leading-[110%] font-helvetica-bold">
            We have a track record of helping companies of all sizes{" "}
            <mark
                /* animation */
                className={`font-helvetica-bold
            transition-colors duration-500
             ${isHovered ? "bg-green-pro" : "bg-transparent"}`}
            >
                transform
            </mark>{" "}
            their operations, create new value propositions, and redefine their
            industries through{" "}
            <span
                className={`dot font-mono text-green-pro ${
                    showDot
                        ? "show w-[30px] md:w-[35px] lg:w-[40px] xl:w-[45px]"
                        : "w-[0px]"
                }`}
            >
                •&#160;
            </span>
            the power of AI.
        </p>
    );
};

const VideoPlayer = () => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(!isClicked);
    };

    const sizes = useWindowSize();

    return (
        <div
            className={`flex flex-row ${
                isClicked ? "ml-auto" : "ml-[-70px]"
            } lg:ml-auto`}
        >
            <motion.div
                style={{
                    width: isClicked
                        ? sizes && sizes.width && sizes.width < 420
                            ? 280
                            : 320
                        : 80,
                    height: isClicked
                        ? sizes && sizes.width && sizes.width < 420
                            ? 183
                            : 210
                        : 80,
                    borderRadius: isClicked ? 0 : "50%",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                }}
                animate={{
                    width: isClicked
                        ? sizes && sizes.width && sizes.width < 420
                            ? 280
                            : 320
                        : 80,
                    height: isClicked
                        ? sizes && sizes.width && sizes.width < 420
                            ? 183
                            : 210
                        : 80,
                    borderRadius: isClicked ? 20 : "50%",
                }}
                transition={{ duration: 0.3 }}
            >
                <motion.video
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        minWidth: "100%",
                        minHeight: "100%",
                        width: "auto",
                        height: "auto",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "50%",
                        border: "2px solid white",
                        zIndex: 20,
                    }}
                    animate={{
                        borderRadius: isClicked ? "20px" : "50%",
                    }}
                    ref={videoRef}
                    playsInline
                    autoPlay
                    loop
                    disablePictureInPicture
                    muted
                    src="./assets/videos/video-home-2.mp4"
                ></motion.video>

                <motion.div
                    onClick={handleClick}
                    className={`bg-black-pro cursor-pointer absolute flex items-center justify-center rounded-full transition-all duration-300 ${
                        isClicked ? "z-30 " : "z-10"
                    }`}
                    animate={{
                        width: isClicked ? "35px" : "80px",
                        height: isClicked ? "35px" : "80px",
                        top: isClicked
                            ? sizes && sizes.width && sizes.width < 420
                                ? "-13px"
                                : "-10px"
                            : "0px",
                        left: isClicked
                            ? sizes && sizes.width && sizes.width < 420
                                ? "260px"
                                : "295px"
                            : "70px",
                    }}
                    transition={{ duration: 0.00005 }}
                >
                    {isClicked ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                        >
                            <XMarkIcon className="w-[15px] h-[15px] text-white" />
                        </motion.div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            style={{
                                marginLeft: "5px",
                            }}
                        >
                            <PlayIcon className="w-[30px] h-[30px] text-white" />
                        </motion.div>
                    )}
                </motion.div>
            </motion.div>
        </div>
    );
};
