import { Benefits } from "../components/incubator/Benefits";
import { HeroIncubator } from "../components/incubator/HeroIncubator";
import { Outcomes } from "../components/incubator/Outcomes";
import { TailoredExperiences } from "../components/incubator/TailoredExperiences";
import { Transformation } from "../components/incubator/Transformation";
import { CallToAction } from "../components/shared/CallToAction";
import { Footer } from "../components/shared/Footer";
import { FooterOdev } from "../components/shared/FooterOdev";

export const IncubatorPage = () => {
    return (
        <div className="flex flex-col">
            <HeroIncubator />
            <Transformation />
            <Outcomes />
            <TailoredExperiences />
            <Benefits />
            <CallToAction
                title="Are you ready to transform your startup with AI?"
                titleTwoGreen=""
                description="Apply to the Pragmatic AI Accelerator Program today and begin your journey to becoming an AI-driven business leader. Join us for three months that will change the way you think about AI in business."
                descriptionTwoBold=""
                textButton="Apply Now"
            />
            <Footer />
            <FooterOdev />
        </div>
    );
};
