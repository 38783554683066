import "./sharedcomponents.css";
import { ArrowRightIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import { motion } from "framer-motion";
import { useContactModalStore } from "../../stores/contactModalStore";

export const ButtonBlack = ({ openModal = false }: { openModal?: boolean }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { showContactModal, hideContactModal, isShownContactModal } =
        useContactModalStore();
    return (
        <div
            className="flex cursor-pointer overflow-hidden"
            id="button-container"
            onMouseEnter={(e) => {
                setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                if (openModal && !isShownContactModal) {
                    showContactModal();
                    return;
                }
                if (openModal && isShownContactModal) {
                    hideContactModal();
                    return;
                }
            }}
        >
            <button
                id="main-button"
                className="py-[6px] px-[16px]  rounded-[54px] cursor-pointer h-[34px] flex items-center relative w-[111px] text-white bg-black-pro"
            >
                <motion.p
                    id="fonts-abs"
                    className="font-helvetica-regular"
                    initial={{ opacity: 1, y: 0 }}
                    animate={{
                        y: isHovered ? -24 : 0,
                    }}
                    transition={{
                        duration: 0.15,
                    }}
                >
                    Contact Us
                </motion.p>

                <motion.p
                    id="bold"
                    className="font-helvetica-bold"
                    initial={{ opacity: 1, y: 24 }}
                    animate={{
                        y: isHovered ? 0 : 24,
                    }}
                    transition={{
                        duration: 0.15,
                    }}
                >
                    Contact Us
                </motion.p>
            </button>
            <div
                id="icon-container"
                className="flex items-center justify-center w-[34px] h-[34px] rounded-[50%] ml-[-5px] bg-black-pro"
            >
                <span
                    id="icon"
                    className="flex items-center text-black-pro justify-center w-[16px] h-[16px]"
                >
                    <ArrowRightIcon
                        className="text-white"
                        fontSize={16}
                        id="ArrowRightIcon"
                    />
                </span>
            </div>
        </div>
    );
};
