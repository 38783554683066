import { RocketLaunchIcon } from "@heroicons/react/16/solid";
import { SuperchargedOutcomes } from "../shared/SuperchargedOutcomes";
import { Title } from "../shared/Title";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import "../shared/sharedcomponents.css";
import { ButtonGreen } from "../shared/ButtonGreen";
import { HighlightText } from "../shared/HighlightText";
import { useWindowSize } from "@uidotdev/usehooks";

const avatars = [
    {
        src: "./assets/images/graham.png",
        alt: "graham",
    },
    {
        src: "./assets/images/jeff.png",
        alt: "jeff",
    },
    {
        src: "./assets/images/kal.png",
        alt: "kal",
    },
    {
        src: "./assets/images/jason.png",
        alt: "jason",
    },
];

export const Mision = () => {
    const size = useWindowSize();
    return (
        <div className="flex flex-col gap-[40px] lg:gap-[0px] lg:flex-row pt-[80px] h-auto w-full px-[20px] sm:px-[60px] xl:pt-[120px] xl:px-[160px] 2xl:px-[220px] justify-between">
            <div className="flex flex-col items-center lg:items-start gap-[60px]">
                <div>
                    <Title title="Our mission" />
                </div>
                <div className="flex flex-col gap-[28px]">
                    <div className="flex justify-center lg:justify-start">
                        {avatars.map((avatar, index) => (
                            <Avatar
                                key={index}
                                src={avatar.src}
                                index={index}
                                alt={avatar.alt}
                            />
                        ))}
                        {size && size?.width && size?.width > 420 && (
                            <SuperchargedOutcomes
                                styles={{
                                    zIndex: 14,
                                    marginLeft: -10,
                                    border: "3px solid #fff",
                                }}
                            />
                        )}
                    </div>
                    <div className="flex flex-row gap-3 items-center justify-center lg:justify-start ">
                        <div className="w-[20px] h-[20px]">
                            <RocketLaunchIcon
                                fontSize={20}
                                style={{
                                    fontSize: 20,
                                }}
                            />
                        </div>
                        <TextFlickering />
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center max-w-full lg:items-start lg:justify-start py-[12px] lg:max-w-[502px] gap-[40px]">
                <p className="font-helvetica-regular text-center lg:text-start text-[20px]">
                    Our mission is to bridge the gap between ambitious business
                    concepts and the transformative power of AI. We understand
                    that AI is more than just a technological tool—it's a
                    catalyst for business growth, efficiency, and competitive
                    advantage. <HighlightText text={"Pragmatic AI"} /> is
                    committed to demystifying AI and making it accessible and
                    actionable for startups focused on delivering exceptional
                    business value.
                </p>
                <ButtonGreen title="Know more about Anserini" path="/about" />
            </div>
        </div>
    );
};

const Avatar = ({
    src,
    alt,
    index,
}: {
    src: string;
    alt: string;
    index: number;
}) => {
    const sizes = useWindowSize();
    return (
        <motion.img
            src={src}
            width={sizes && sizes.width && sizes.width > 370 ? 80 : 60}
            height={sizes && sizes.width && sizes.width > 370 ? 80 : 60}
            alt={alt}
            style={{
                zIndex: 10 + index + 1,
                marginLeft: index > 0 ? -10 : 0,
            }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
        />
    );
};

const TextFlickering = () => {
    const title = "Founders of Anserini";
    const title2 = "17+ Years of Experience";
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setIsHovered((prevHovered) => !prevHovered);
        }, 1500); // Change the number to adjust the speed of the loop

        return () => clearInterval(interval); // Clean up on unmount
    }, []);

    return (
        <div className="flex overflow-hidden" id="button-container">
            <button
                id="main-button"
                className="py-[8px] px-[12px] md:px-[18px] justify-center rounded-[54px] h-[44px] flex items-center relative text-black-pro"
                style={{
                    width:
                        window.innerWidth >= 768
                            ? title2.length * 8 + 30
                            : title2.length * 7 + 40,
                }}
            >
                <motion.p
                    className="font-helvetica-regular text-[16px] md:text-[20px]"
                    initial={{ opacity: 1, y: 0 }}
                    animate={{
                        y: isHovered ? -35 : 0,
                    }}
                    transition={{
                        duration: 0.15,
                    }}
                >
                    {title}
                </motion.p>

                <motion.p
                    id="bold"
                    className="font-helvetica-regular text-[16px] md:text-[20px]"
                    initial={{ opacity: 1, y: 35 }}
                    animate={{
                        y: isHovered ? 0 : 35,
                    }}
                    transition={{
                        duration: 0.15,
                    }}
                >
                    {title2}
                </motion.p>
            </button>
        </div>
    );
};
